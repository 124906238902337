import Router, { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';

import { Role } from '../../../utils/api';
import { useRolesCheck } from '../../../utils/auth';
import { handleError } from '../../../utils/errors';
import { Footer } from '../common';
import WhatsappButton from '../common/whatsapp-button';

import MainAppBar from './main-app-bar';
import Navbar from './navbar-app';
import Paywall from './paywall';

import styles from './layout.module.scss';

interface Props {
  requiredRoles?: Role[];
  showCatchphrase?: boolean;
}

export default function MainLayout({ children, requiredRoles = [] }: PropsWithChildren<Props>) {
  const router = useRouter();
  const { shouldRedirectToLogin, companyNeedingUpdate } = useRolesCheck(...requiredRoles);

  useEffect(() => {
    if (companyNeedingUpdate && !/^\/me(\/|$)/.test(router.pathname))
      Router.push(`/me/edit/${companyNeedingUpdate.slug ?? companyNeedingUpdate.id}`).catch(handleError);
  }, [companyNeedingUpdate, router.pathname]);

  return (
    <>
      <MainAppBar/>
      <div className={styles.content}>
        <Navbar />
          {/* {showCatchphrase && (
            <Catchphrase>
              mulheres <b>fortes</b>, <b>corajosas</b>, <b>femininas</b> e <b>prósperas</b>
            </Catchphrase>
          )} */}
          {shouldRedirectToLogin && <Paywall />}
        
          {children}
        
        <WhatsappButton />
        <Footer />
      </div>
    </>
  );
}
