import { Box, Button, Tooltip, TextField } from '@mui/material';
import Router from 'next/router';
import Link from 'next/link';
import { FormEvent, useCallback, useState, useRef } from 'react';
import { IconArrowNarrowRight, IconLogout, IconUser, IconSearch } from '@tabler/icons-react';

import { handleError } from '../../../utils/errors';
import { useStringParam } from '../../../utils/hooks';
import { useLogoutCallback, useUserInfo } from '../../../utils/auth';
import { TopLogoButton } from '../../logo';

import SearchBox from './search-box';
import MyProfileButton from './my-profile-button';

import styles from './layout.module.scss';

export default function Navbar() {
  const myInfo = useUserInfo();
  const isAuthenticated = myInfo?.isAuthenticated;
  const handleLogout = useLogoutCallback();
  const inputRef = useRef<HTMLInputElement>();

  const handleSearch = useCallback((e: FormEvent) => {
    e.preventDefault();
    Router.push({ pathname: '/search', query: { q: inputRef.current?.value } }).catch(handleError);
  }, []);

  const [divClass, setDivClass] = useState('');



  return (
    <Box className={styles.navbar}>
      <Box className={styles.destkop} display="grid" alignItems="center" gridTemplateColumns="repeat(12, 1fr)" gap={2} justifyContent="space-between">
        <Box gridColumn="span 6">
          <SearchBox />
        </Box>
        <Box gridColumn="span 6" textAlign="right">
          {(() => {
              if (isAuthenticated){
                  return (
                    <Box display="flex" justifyContent="end" width="100%" alignItems="center">
                      
                      <MyProfileButton />
                      <Link href="#" onClick={handleLogout} className={styles.logout} >
                        <Tooltip title="Sair da conta" arrow placement="bottom">
                          <IconLogout stroke={1.5} />
                        </Tooltip>
                      </Link>
                    </Box>
                    
                  )
              }else{
                return (
                  <Box display="flex" justifyContent="end" width="100%" alignItems="center">
                    <Link href="/auth/login" className={styles.login}>
                       <IconUser stroke={1.2} /> <span>Acessar conta</span>
                    </Link>
                    <Link href="https://sou-socia.clubw2w.com.br/" target="_blank" rel="noreferrer">
                      <Button>Seja uma sócia <Box paddingLeft="20px" paddingTop="5px"><IconArrowNarrowRight stroke={1} /></Box></Button>
                    </Link>
                  </Box>
                )
              }
          })()}
        </Box>
      </Box>
      <Box className={styles.mobile} display="grid" alignItems="center" gridTemplateColumns="repeat(12, 1fr)" gap={2} justifyContent="space-between">
        <Box gridColumn="span 6">
          <TopLogoButton />
        </Box>
        <Box gridColumn="span 6" textAlign="right">
          {(() => {
              if (isAuthenticated){
                  return (
                    <Box display="flex" justifyContent="end" width="100%" alignItems="center">
                      <button onClick={() => setDivClass('active')} className={styles.searchMobile} >
                        <IconSearch stroke={1.5} />
                      </button>
                      <MyProfileButton />
                      <Link href="#" onClick={handleLogout} className={styles.logout} >
                        <Tooltip title="Sair da conta" arrow placement="bottom">
                          <IconLogout stroke={1.5} />
                        </Tooltip>
                      </Link>
                      <Box className={styles.searchMobileBox+" "+divClass}>
                        <form onSubmit={handleSearch} action="/search">
                          <IconSearch stroke={1.2} />
                          <TextField
                            placeholder="Busque por sócias, serviços ou cidades"
                            type="search"
                            name="q"
                            defaultValue={useStringParam('q')}
                            inputRef={inputRef}
                            inputProps={{ 'aria-label': 'search' }}
                          />
                        </form>
                      </Box>
                    </Box>
                  )
              }else{
                return (
                  <Box display="flex" justifyContent="end" width="100%" alignItems="center">
                    <Link href="/auth/login" className={styles.login}>
                       <IconUser stroke={1.2} />
                    </Link>
                    <Link href="https://sou-socia.clubw2w.com.br/" target="_blank" rel="noreferrer">
                      <Button>Seja uma sócia <Box paddingLeft="20px" paddingTop="5px"><IconArrowNarrowRight stroke={1} /></Box></Button>
                    </Link>
                  </Box>
                )
              }
          })()}
        </Box>
      </Box>
    </Box>
    
  );
}
