import Link from 'next/link';
import { Box } from '@mui/material';
import { IconMessageCircleQuestion } from '@tabler/icons-react';

import styles from '../main-layout/layout.module.scss';


export default function WhatsappButton() {

  return (
    <Link href="https://api.whatsapp.com/send?phone=5548998420089&text=">
      <Box  className={styles.whatsappFlutuante}>
        <IconMessageCircleQuestion stroke={1.5} />
      </Box>
    </Link>
  );
}
