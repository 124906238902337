import { Box, BoxProps } from '@mui/material';

import styles from './footer.module.scss';

export default function Footer({ sx }: { sx?: BoxProps['sx'] }) {
  return (
    <Box component="footer" className={styles.footer} sx={sx}>
      <p>
        Copyright © 2023 - W2W ® All rights reserved. Design by <a href="https://stun.digital" target="_blank" rel="noreferrer"><img src="https://stun.digital/assets/img/logo_footer_dark.svg"></img></a>
      </p>

    </Box>
  );
}
