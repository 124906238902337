import _ from 'lodash';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR, { Key, useSWRConfig } from 'swr';

export function useStringParam(key: string): string {
  const v = useRouter().query[key];
  return Array.isArray(v) ? v.join(',') : v ?? '';
}

/**
 * Returns an object containing a callable, sync-safe callback (the `handler` property) and some state variables
 * to check the current async state (NotStarted, Running, Finished or HasErrors).
 */
export function useAsyncCallback<T extends unknown[]>(
  callback: (...args: T) => Promise<unknown>,
  deps: DependencyList,
) {
  const [[error, isRunning], setState] = useState<[Error | null, boolean]>([null, false]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const handler = useCallback(
    (...args: T) => {
      setState([null, true]);
      NProgress.start();
      Promise.resolve(callback(...args))
        .then((r) => {
          if (mounted.current) setState([null, false]);
          return r;
        })
        .catch((e) => {
          if (mounted.current) setState([e, false]);
          if (e !== undefined) throw e;
        })
        .finally(() => {
          NProgress.done();
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, setState, callback],
  );

  return { isRunning, error, handler };
}

export function useDebouncedCallback<T extends unknown[]>(
  func: (...args: T) => unknown,
  deps: DependencyList,
  ms: number,
) {
  const debouncedFunc = useMemo(() => _.debounce(func, ms), [func, ms]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args: T) => debouncedFunc(...args), [debouncedFunc, ...deps]);
}

export function useSWRMultiMutator() {
  const { cache, mutate } = useSWRConfig();

  // noinspection SuspiciousTypeOfGuard
  if (!(cache instanceof Map)) throw new Error('useSWRMultiMutator requires the cache provider to be a Map instance');

  return (...matchers: (RegExp | string | ((key: string) => boolean))[]) => {
    return Promise.all(
      [...cache.keys()]
        .filter((k: string) =>
          matchers.some((m) => {
            if (typeof m === 'function') return m(k);
            if (m instanceof RegExp) return m.test(k);
            return String(m) === k;
          }),
        )
        .map((key: string) => mutate(key)),
    );
  };
}

export function useSWRForEditing<T, TError = Error>(key: Key) {
  return useSWR<T, TError>(key, {
    suspense: true,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
}
