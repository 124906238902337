import { IconSearch } from '@tabler/icons-react';
import { InputBase, styled } from '@mui/material';
import Router from 'next/router';
import { FormEvent, useCallback, useRef } from 'react';

import { handleError } from '../../../utils/errors';
import { useStringParam } from '../../../utils/hooks';

export default function SearchBox({}: { large?: boolean }) {
  const inputRef = useRef<HTMLInputElement>();

  const handleSearch = useCallback((e: FormEvent) => {
    e.preventDefault();
    Router.push({ pathname: '/search', query: { q: inputRef.current?.value } }).catch(handleError);
  }, []);

  return (
    <SearchWrapper onSubmit={handleSearch} action="/search">
      <SearchIconWrapper>
        <IconSearch stroke={1.2} />
      </SearchIconWrapper>
      <SearchInput
        placeholder="Busque por sócias, serviços ou cidades"
        type="search"
        name="q"
        defaultValue={useStringParam('q')}
        inputRef={inputRef}
        inputProps={{ 'aria-label': 'search' }}
      />
    </SearchWrapper>
  );
}

const searchFgColor = '#fff';

const SearchWrapper = styled('form')(({ theme }) => ({
  position: 'relative',
  borderRadius: '30px',
  backgroundColor: searchFgColor,
  color: '#8B8B8B',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  display: 'flex',
  border: '.5px solid #E0E0E0',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '44ch',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SearchInput = styled(InputBase)(({ }) => ({
  flex: 1,
  '& .MuiInputBase-input': {
    padding: '14px 0px',
    width: '100%',
    fontSize: '13px',
    '&::placeholder': {
      color: '#333',
      opacity: 1,
    },
  },
}));
