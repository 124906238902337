import { Avatar, AvatarProps } from '@mui/material';
import MD5 from 'crypto-js/md5';
import Image from 'next/image';
import { ElementType, useMemo } from 'react';

import { fixImageUrl } from '../../utils/images';

export interface Props {
  width: number;
  height: number;
  avatar?: string | undefined;
  email?: string | undefined;
  sx?: AvatarProps['sx'];
  variant?: AvatarProps['variant'];
  component?: ElementType;
  priority?: boolean;
}

export default function ProfileAvatar({ width, height, avatar, email, priority, sx, ...props }: Props) {
  const avatarUrl = useMemo(() => {
    if (avatar) return fixImageUrl(avatar);

    if (email) return `https://www.gravatar.com/avatar/${MD5(email).toString()}?s=${Math.max(width, height)}&d=mp`;

    return undefined;
  }, [avatar, email, width, height]);

  return (
    <Avatar variant="rounded" sx={{ ...sx, width, height }} {...props}>
      {avatarUrl && (
        <Image
          src={avatarUrl}
          alt="Avatar"
          width={width}
          height={height}
          style={{ objectFit: 'cover' }}
          priority={priority}
        />
      )}
    </Avatar>
  );
}
