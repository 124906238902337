import { useLogoutCallback, useUserInfo } from '../../../utils/auth';
// import { useMessageCount } from '../../../utils/messages';
import { DesktopMenuButton } from '../common';

import { IconHome, IconLetterB, IconHash, IconMessageCircle, IconVideo, IconHelp, IconLogout, IconLogin } from '@tabler/icons-react';



export default function DesktopMenu() {
  const myInfo = useUserInfo();
  const handleLogout = useLogoutCallback();
  const isAuthenticated = myInfo?.isAuthenticated;
  // const unreadMessageCount = useMessageCount();

  return (
    <>
      <div>
        <DesktopMenuButton label="Home" href="/" icon={<IconHome stroke={1.5} />} />
        <DesktopMenuButton label="Blog" href="/posts" icon={<IconLetterB stroke={1.5} />} />
        <DesktopMenuButton label="Rede social" href="/feed" icon={<IconHash stroke={1.5} />} />
        <DesktopMenuButton label="Mensagens" href="/messages" icon={<IconMessageCircle />} />
        {/* <DesktopMenuButton label="Mensagens" href="/messages" icon={<IconMessageCircle />} badge={unreadMessageCount} /> */}
        <DesktopMenuButton label="Aulas" href="/classes" icon={<IconVideo stroke={1.5} />} />
      </div>

      <div>
        <DesktopMenuButton label="Dúvidas" href="/faq" icon={<IconHelp stroke={1.5} />} />
        {isAuthenticated ? (
          <DesktopMenuButton label="Sair" href="#" onClick={handleLogout} icon={<IconLogout stroke={1.5} />} />
        ) : (
          <DesktopMenuButton label="Entrar" href="/auth/login" icon={<IconLogin stroke={1.5} />} />
        )}
      </div>
    </>
  );
}
