import { Box, BoxProps } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import styles from './logo.module.scss';

interface Props {
  sx?: BoxProps['sx'];
}

export default function Logo({ sx }: Props) {
  return (
    <Box
      className={styles.logo}
      sx={{ height: 'auto', ...sx}}
    >
      <img src="/assets/logo-marca.svg"></img>
      <Box sx={visuallyHidden}>PortalW</Box>
    </Box>
  );
}
