import { ChevronLeft } from '@mui/icons-material';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

import BrandedButton from './branded-button';
import styles from './layout.module.scss';

interface Props {
  href?: LinkProps['href'];
}

export default function BackButton({ href }: Props) {
  const router = useRouter();

  return (
    <BrandedButton className={styles.backBox} href={href ? href : ''} onClick={href ? undefined : router.back} startIcon={<ChevronLeft />}>
      Voltar
    </BrandedButton>
  );
}
