import { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

import api from './api';

export function useRealUrl(src: ImageProps['src'] | Blob): string;
export function useRealUrl(src: ImageProps['src'] | Blob | undefined): string | undefined;
export function useRealUrl(src: ImageProps['src'] | Blob | undefined) {
  const [realUrl, setRealUrl] = useState<ImageProps['src']>();

  useEffect(() => {
    setRealUrl((oldUrl) => {
      if (oldUrl && typeof oldUrl === 'string' && oldUrl.startsWith('blob:')) URL.revokeObjectURL(oldUrl);

      return fixImageUrl(src);
    });

    return () => {
      setRealUrl(undefined);
    };
  }, [src]);

  return realUrl;
}

export function fixImageUrl(src: string | Blob): string;
export function fixImageUrl(src: string | Blob | undefined): string | undefined;
export function fixImageUrl(src: ImageProps['src'] | Blob): ImageProps['src'];
export function fixImageUrl(src: ImageProps['src'] | Blob | undefined): ImageProps['src'] | undefined;
export function fixImageUrl(src: ImageProps['src'] | Blob | undefined) {
  if (typeof src === 'string')
    return /^uploads\/images?\//.test(src) ? new URL(`/api/${src}`, location.href).toString() : undefined;
  if (typeof File !== `undefined` && src instanceof File && !src.type.startsWith('image/')) return undefined;
  if (typeof Blob !== 'undefined' && src instanceof Blob) return URL.createObjectURL(src);

  return src;
}

export function fixUploadUrl(src: string): string;
export function fixUploadUrl(src: string | undefined): string | undefined;
export function fixUploadUrl(src: string | undefined) {
  return typeof src === 'string' && /^uploads\//.test(src) ? `${api.baseURL}api/${src}` : src;
}
