import { Button, ButtonProps } from '@mui/material';
import Link, { LinkProps } from 'next/link';
import { PropsWithChildren } from 'react';

interface Props extends Omit<ButtonProps, 'href'> {
  href?: LinkProps['href'];
}

export default function BrandedButton({ children, href, sx, ...props }: PropsWithChildren<Props>) {
  const btn = (
    <Button disableElevation {...props} sx={{ ...sx, textTransform: 'unset', fontWeight: 400, borderRadius: '10px' }}>
      {children}
    </Button>
  );

  return href ? (
    <Link href={href} passHref legacyBehavior>
      {btn}
    </Link>
  ) : (
    btn
  );
}
