import { BoxProps, ButtonBase } from '@mui/material';
import Link, { LinkProps } from 'next/link';

import Logo from './logo';

interface Props {
  href?: LinkProps['href'];
  sx?: BoxProps['sx'];
}

export default function TopLogoButton({ sx, href = '/' }: Props) {
  return (
    <Link href={href} passHref legacyBehavior>
      <ButtonBase focusRipple>
        <Logo sx={sx} />
      </ButtonBase>
    </Link>
  );
}
