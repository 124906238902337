import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import Router, { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';

import api, { CompanyNeedsUpdate, IMyInfo, Role } from './api';
import { handleError } from './errors';

export function useLogoutCallback(message = 'Logout realizado, volte sempre!') {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    api
      .logout()
      .then(() => mutate<IMyInfo>('/api/me', (me) => me && { ...me, isAuthenticated: false }, { revalidate: false }))
      .then(() => Bugsnag.setUser())
      .then(() => Router.push('/'))
      .then(() => enqueueSnackbar(message, { variant: 'info' }))
      .catch(handleError);
  }, [mutate, enqueueSnackbar, message]);
}

export function useUserInfo() {
  const { data: me } = useSWR<IMyInfo>('/api/me');

  Bugsnag.setUser(me && String(me.id), me?.email, me?.name);

  return me;
}

/**
 * Checks whether the current user should be logged out.
 */
export function useRolesCheck(...requiredRoles: Role[]) {
  const { data: myInfo, error, mutate, isLoading } = useSWR<IMyInfo, Error>('/api/me');

  const isError401 = axios.isAxiosError(error) && error.response?.status === 401;

  // em caso de erro 401, marca o usuário como não-autenticado
  useEffect(() => {
    if (!isLoading && isError401 && myInfo?.isAuthenticated) {
      mutate((me) => (!me ? me : { ...me, isAuthenticated: false }), { revalidate: false }).catch(handleError);
    }
  }, [isError401, isLoading, mutate, myInfo?.isAuthenticated]);

  // deve redirecionar para o login se...
  const shouldRedirectToLogin = useMemo(
    () =>
      // ...já carregamos as informações do usuário (com sucesso ou erro), e...
      (!isLoading || isError401) &&
      // ...há pelo menos um papel obrigatório, e...
      requiredRoles.length > 0 &&
      (isError401 || //  ...verificamos que o usuário não possui cookie, ou
        !myInfo?.isAuthenticated || // ...possui mas sabemos que não está autenticado, ou
        !myInfo.roles || // o usuário não tem papéis definidos, ou
        requiredRoles.some((r) => !myInfo.roles.includes(r))), //  não possui algum dos papéis necessários
    [isError401, isLoading, myInfo?.isAuthenticated, myInfo?.roles, requiredRoles],
  );

  const companyNeedingUpdate = myInfo?.companies?.find((c) => c.needsUpdate === CompanyNeedsUpdate.Blocked);

  const { enqueueSnackbar } = useSnackbar();
  const redirectToLogin = useCallback(() => {
    Router.push('/auth/login')
      .then(() =>
        enqueueSnackbar('É necessário se identificar no sistema para acessar o conteúdo restrito.', {
          variant: 'warning',
        }),
      )
      .catch(handleError);
  }, [enqueueSnackbar]);

  const isAdmin = myInfo?.roles?.includes('Admin');

  return { myInfo, shouldRedirectToLogin, redirectToLogin, isAdmin, companyNeedingUpdate };
}

/**
 * Checks whether the current user should be logged out, or is missing one of the onboarding steps.
 */
export function useRedirectIfNeeded(...requiredRoles: Role[]) {
  const { pathname } = useRouter();

  const { myInfo, shouldRedirectToLogin, redirectToLogin, isAdmin } = useRolesCheck(...requiredRoles);

  useEffect(() => {
    if (shouldRedirectToLogin) {
      redirectToLogin();
      return;
    }

    // adia o resto do processamento até ter mais informações do usuário logado
    if (!myInfo) return;

    // se o usuário não é admin e não está em uma página de onboarding, direciona à página de onboarding correta
    if (!isAdmin && !/^\/onboarding\/.+/.test(pathname)) {
      const newOnboardingUrl = getOnboardingUrl(myInfo?.completedStep);
      if (newOnboardingUrl) {
        Bugsnag.leaveBreadcrumb('Onboarding incomplete', { redirectingTo: newOnboardingUrl }, 'navigation');
        Router.replace(newOnboardingUrl).catch(handleError);
        return;
      }
    }
  }, [pathname, myInfo, shouldRedirectToLogin, redirectToLogin, isAdmin]);
}

export function getUrlAfterLogin(myInfo: IMyInfo) {
  if (!myInfo.isAuthenticated) return '/auth/login';

  return getOnboardingUrl(myInfo?.completedStep) ?? '/';
}

export function getOnboardingUrl(completedStep: number | undefined) {
  switch (completedStep) {
    case 0:
      return '/onboarding/step-one';
    case 1:
      return '/onboarding/step-two';
    case 2:
      return '/onboarding/step-three';
    default:
      return undefined;
  }
}
