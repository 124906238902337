import { Box, MenuItem } from '@mui/material';
import Link from 'next/link';
import { PropsWithChildren, ReactNode } from 'react';

interface Props {
  icon: ReactNode;
  href?: string;

  onClick?: () => void;
}

export default function PopupMenuItem({ icon, children, onClick, href }: PropsWithChildren<Props>) {
  const mi = (
    <MenuItem sx={{ display: 'flex', alignItems: 'center', lineHeight: '1.1em' }} onClick={onClick}>
      <Box minWidth="2em">{icon}</Box>
      {children}
    </MenuItem>
  );

  return !href ? (
    mi
  ) : (
    <Link href={href} passHref legacyBehavior>
      {mi}
    </Link>
  );
}
