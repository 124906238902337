import { AppBar, Box, Toolbar } from '@mui/material';

import { TopLogoButton } from '../../logo';

import DesktopMenu from './desktop-menu';

import styles from './layout.module.scss';


export default function MainAppBar() {
  return (
    <AppBar className={styles.appbar}>
      <Toolbar disableGutters className={styles.toolbar}>
        <TopLogoButton />

        <Box>
          <Box sx={{ gap: 2 }} className={styles.menu_desktop}>
            <DesktopMenu />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
