import { Button } from '@mui/material';
import Link from 'next/link';

export default function LoginButton() {
  return (
    <Link href="/auth/login" passHref legacyBehavior>
      <Button disableElevation color="inverted" sx={{ ml: 2, alignSelf: 'center' }}>
        Login
      </Button>
    </Link>
  );
}
