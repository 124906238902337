import { AccountCircle, AddBusiness, Business } from '@mui/icons-material';
import { Divider, Menu, ButtonBase, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useId, useState } from 'react';

import { useUserInfo } from '../../../utils/auth';
import { PopupMenuItem } from '../common';
import SelfAvatar from '../../profiles/self-avatar';

export default function MyProfileButton() {
  const myInfo = useUserInfo();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      setAnchorElUser(e.currentTarget);
    },
    [setAnchorElUser],
  );
  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, [setAnchorElUser]);

  const id = useId();


  return (
    <>

      <Tooltip title="" arrow placement="right">
        <ButtonBase focusRipple onClick={handleOpenUserMenu}>
          <SelfAvatar width={45} height={45} variant="circular" priority />
        </ButtonBase>
      </Tooltip>

      <Menu
        id={id}
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <PopupMenuItem href="/me" icon={<AccountCircle />}>
          Perfil Pessoal
        </PopupMenuItem>
        <Divider />
        {myInfo?.companies?.map(({ slug, id, name }) => (
          <PopupMenuItem key={id} href={`/companies/${slug ?? id}`} icon={<Business />}>
            {name}
          </PopupMenuItem>
        ))}
        <PopupMenuItem href="/me/edit/new" icon={<AddBusiness />}>
          Adicionar Negócio
        </PopupMenuItem>
      </Menu>
    </>
  );
}
