import { useUserInfo } from '../../utils/auth';

import ProfileAvatar, { Props as AvatarProps } from './profile-avatar';

type Props = Omit<AvatarProps, 'avatar' | 'email'>;

export default function SelfAvatar(props: Props) {
  const myInfo = useUserInfo();

  return <ProfileAvatar {...props} avatar={myInfo?.avatar} email={myInfo?.email} />;
}
