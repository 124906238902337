import { Badge, ButtonBase, ButtonBaseProps, Tooltip } from '@mui/material';
import Link from 'next/link';
import { useRouter } from "next/router";
import { ReactNode } from 'react';

import styles from '../main-layout/layout.module.scss';

interface Props extends Pick<ButtonBaseProps, 'onClick'> {
  icon: ReactNode;
  label: ReactNode;
  badge?: ReactNode;
  href?: string;
}


export default function DesktopMenuButton({ icon, label, href, badge, ...props }: Props) {

  const router = useRouter();

  const bb = (
    <Tooltip title={label} arrow placement="right">
      <ButtonBase focusRipple {...props}>
        {!badge ? (
          icon
        ) : (
          <Badge badgeContent={badge} color="secondary">
            {icon}
          </Badge>
        )}
      </ButtonBase>
    </Tooltip>
  );

  return !href ? (
    bb
  ) : (
    <Link href={href} passHref legacyBehavior>
      <a className={ router.pathname.match(href) && href != '/' ? styles.active : (href === '/' && router.pathname === '/' ? styles.active : '')}>
        {bb}
        <span>{label}</span>
      </a>
    </Link>
  );
}
